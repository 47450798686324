


@font-face {
  font-family: 'oraqle';
  src: url('./assets/fonts/oraqle-re.ttf');
  }

  .titulo {
    font-family: 'oraqle';
    text-align: center;
    color : #ffffff;
    font-size: 194px;
  }

.App {
  text-align: center;
}

.App-logo {
    width:100%;
}

.App-mancha {
  block-size: inherit;
}

.App-header {
  top: -15px;
  background-color: #ffffff;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(25px + 2vmin);
  color: #6b90da;
}

.App-link {
  color: #09d3ac;
}

.bodyvidrio {
  background-attachment: fixed; 
  background-size: cover;
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-left: -15rem;
}

.vidrio {
  /*height: 20rem;*/
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);   
  border-radius: 5px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.vidrio:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  filter: blur(3px);
  margin: -20px;
}

.transformed {
  background-image: url(./assets/img/manchaazul.svg), none;
  transform: rotate(-25deg);
  color: azure;
}

.shieldcontainer{
  background-color: #054fd4;
  width: 8.5rem;
  height: 8.5rem;
}

.container__star,
.container__star::before,
.container__star::after{
    width: 0;
    border-color: transparent transparent #fff transparent;
    border-width: 0 4rem 2.5rem 4rem;
    border-style: solid;
    position: absolute;
    transform: rotate(180deg);
 
}

.container__star::before{
    content: '';
    margin-top: -0.15rem;
      margin-left: -4.3rem;
    transform: rotate(70deg);
}

.container__star::after{
    content: '';
    margin-top: -0.29rem;
      margin-left: -3.87rem;
    transform: rotate(-70deg);
}

CSS
.circulo {
     width: 100px;
     height: 100px;
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
     border-radius: 50%;
     background: red;
}

.rectangulo {
  width: 300%;
  height: 150px;
  background: red;
  z-index: -1;
}

.bigotes {
  color: #ffffff;
  font-size: 329px;
}